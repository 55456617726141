<template>
  <div>
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <span
          class="gray"
        >
          Modifier une formation
        </span>
        <v-icon
          top
          right
        >
          {{ icons.mdiPencil }}
        </v-icon>
      </v-card-title>
      <v-divider>
      </v-divider>
      <v-skeleton-loader
        v-if="loading"
        class="mx-auto"
        type="list-item-two-line, list-item-two-line, list-item-two-line"
      ></v-skeleton-loader>
      <v-card-text v-else>
        <v-row>
          <v-col
            md="6"
            sm="12"
          >
            <v-form>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="newFormation.nom"
                    label="Nom de la formation"
                    outlined
                    dense
                    placeholder="Nom de la formation"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="newFormation.prix"
                    label="Prix de la formation"
                    outlined
                    dense
                    type="number"
                    placeholder="Prix de la formation"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="newFormation.duree"
                    label="Durée de la formation"
                    outlined
                    dense
                    type="number"
                    placeholder="Durée de la formation"
                    :hint="`${Number(newFormation.duree)} heures dont ${Number(newFormation.visio)} heures de visio`"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="newFormation.visio"
                    label="Durée de la visio"
                    outlined
                    dense
                    type="number"
                    placeholder="Durée de la visio"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-model="selectedCert"
                    :items="certifications"
                    item-text="nom"
                    item-value="id"
                    label="Certification"
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col>
                  <v-select
                    v-model="newFormation.tuteur"
                    :items="tuteurs"
                    item-text="nom"
                    item-value="id"
                    label="Tuteur"
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-col
            v-if="newFormation.students"
            md="6"
            sm="12"
          >
            <span class="subtitle-1 mb-3">Liste des apprenants ({{ newFormation.students.length }})</span>
            <v-list
              rounded
              dense
              height="250"
              class="overflow-auto"
            >
              <v-list-item-group
                v-model="selectedStudent"
                color="primary"
              >
                <v-list-item
                  v-for="(student, i) in newFormation.students"
                  :key="i"
                  @click="gotoStud(student.id)"
                >
                  <v-list-item-icon>
                    <v-icon>{{ icons.mdiAccount }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ student.nom }} {{ student.prenom }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex">
        <v-dialog
          v-model="dialog"
          width="500"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              color="error"
              dark
              v-bind="attrs"
              outlined
              v-on="on"
            >
              Supprimer la formation
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Attention
            </v-card-title>

            <v-card-text>
              Toute suppression est définitive. <br />
              La suppression d'une formation affectée à des apprenants peut poser problème pour la création de documents et d'autres éléments.
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="deleteFormation"
              >
                Supprimer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="editFormation"
        >
          Modifier
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mdiAccount, mdiPencil } from '@mdi/js'

export default {
  components: {
  },
  data() {
    return {
      selectedStudent: '',
      dialog: false,
      loading: true,
      selectedCert: 0,
      newFormation: {
        nom: '',
        duree: '',
        tuteur: [],
        prix: 0,
        certifications: '',
      },
      tuteurs: [],
      certifications: [],
      icons: {
        mdiAccount,
        mdiPencil,
      },
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      Promise.all([this.fetchTuteurs(), this.fetchFormation(), this.fetchCertifs()])
        .then(values => {
          [this.tuteurs, this.newFormation, this.certifications] = values

          const matchingCert = this.certifications.find(x => x.id === this.newFormation.certification)
          if (typeof matchingCert === 'undefined') {
            this.$store.commit('snackMe', {
              color: 'error',
              text: 'La certification correspondante n\'a pas été trouvée.',
              value: true,
            })
          } else {
            this.selectedCert = matchingCert.id
          }
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.loading = false
        })
    },
    async fetchTuteurs() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/tuteurs`)

      return response.data
    },
    async fetchFormation() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/formations/${this.$route.params.id}`)

      return response.data
    },
    async fetchCertifs() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/certifications`)

      return response.data
    },

    deleteFormation() {
      this.$http.delete(`${process.env.VUE_APP_API_URL}/formations/${this.$route.params.id}`)
        .then(() => {
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Formation supprimée',
            value: true,
          })
        }).catch(err => {
          console.log(err)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la suppression de la formation',
            value: true,
          })
        })
    },
    editFormation() {
      if (!this.newFormation.certification || this.newFormation.certification.id !== this.selectedCert) {
        const matchingCert = this.certifications.find(x => x.id === this.selectedCert)
        const updatedFormations = matchingCert.formations
        updatedFormations.push(this.newFormation.id)

        this.$http.put(`${process.env.VUE_APP_API_URL}/certifications/${this.selectedCert}`, { formations: updatedFormations })
          .then(res => {
            const certIndex = this.certifications.findIndex(x => x.id === this.selectedCert)
            this.certifications[certIndex] = res.data
            this.$store.commit('snackMe', {
              color: 'success',
              text: 'Certification mise à jour',
              value: true,
            })
          }).catch(err => {
            console.log(err)
            this.$store.commit('snackMe', {
              color: 'error',
              text: 'Erreur lors de la modification de la certfication',
              value: true,
            })
          })
      }
      this.$http.put(`${process.env.VUE_APP_API_URL}/formations/${this.$route.params.id}`, this.newFormation)
        .then(res => {
          this.newFormation = res.data
          this.newFormation.certification = this.newFormation.certification.id
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Formation mise à jour',
            value: true,
          })
        }).catch(err => {
          console.log(err)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la modification de la formation',
            value: true,
          })
        })
    },
    gotoStud(id) {
      this.$router.push({ name: 'student-single', params: { id } })
    },
  },
}
</script>

<style>

</style>
